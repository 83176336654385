import Readmore from "./readmore";
import Slideshow from "./slideshow";

const Ajax = {
  init: () => {
    Ajax.active = true;

    $(window).on("scroll", function () {
      var scrollTop = $(window).scrollTop();
      var totalScroll = $(".js-main").outerHeight() - $(window).outerHeight();
      if (scrollTop > totalScroll - 800 && Ajax.active) {
        Ajax.active = false;
        Ajax.loadNextProjects();
        setTimeout(() => {
          Ajax.active = true;
        }, 1000);
      }
    });
  },

  loadNextProjects: () => {
    var html = null;
    var href = $(".js-column")
      .first()
      .find(".js-projectsPagination")
      .attr("href");

    $.get(href, function (data) {
      html = $(data).find(".js-column").first().find(".js-projects").html();
      return true;
    }).done(function () {
      $(".js-column").each(function (i, el) {
        $(el).find(".js-projectsPagination").remove();
        $(el).find(".js-projects").append(html);
        Ajax.initFunctions();
      });
    });
  },

  initFunctions: () => {
    Readmore.init();
    Slideshow.init();
  },
};

export default Ajax;
