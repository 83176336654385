const Cursor = {
  init: () => {
    $(window).on("mousemove", function (e) {
      $(".js-cursor").css({ opacity: 1 });
      var half = $(window).width() / 2;
      if (e.clientX > half) {
        $(".js-cursor").css({
          left: e.clientX - half,
          top: e.clientY,
        });
      } else {
        $(".js-cursor").css({
          left: e.clientX + half,
          top: e.clientY,
        });
      }

      var currentCursor = $(e.target).css("cursor");
      if (currentCursor.includes("pointer")) {
        $(".js-cursor").children().removeClass("active");
        $(".js-cursor").find(".pointer").addClass("active");
      } else if (currentCursor.includes("text")) {
        $(".js-cursor").children().removeClass("active");
        $(".js-cursor").find(".text").addClass("active");
      } else {
        $(".js-cursor").children().removeClass("active");
        $(".js-cursor").find(".default").addClass("active");
      }
    });

    $(document).on("mouseleave", function () {
      $(".js-cursor").css({ opacity: 0 });
    });

    $(document).on("mouseenter", function () {
      $(".js-cursor").css({ opacity: 1 });
    });
  },
};

export default Cursor;
