const Slideshow = {
  init: () => {
    $(".js-prev")
      .off("click")
      .on("click", function () {
        Slideshow.prev($(this));
      });

    $(".js-next")
      .off("click")
      .on("click", function () {
        Slideshow.next($(this));
      });

    $(".js-slide")
      .off("click")
      .on("click", function () {
        Slideshow.next(null, $(this).parent(".js-slideshow"));
      });
  },

  next: (button, slideshow = null) => {
    if (slideshow == null) {
      var slideshow = $(button)
        .parents(".js-slideshowContainer")
        .find(".js-slideshow");
    }
    var el = $(slideshow).find(".js-slide.active");
    var i = $(slideshow).find(".js-slide").index(el);
    var length = $(slideshow).find(".js-slide").length;
    var nextEl = i > length - 2 ? 0 : i + 1;
    Slideshow.change(i, nextEl, slideshow);
  },

  prev: (button) => {
    var slideshow = $(button)
      .parents(".js-slideshowContainer")
      .find(".js-slideshow");
    var el = $(slideshow).find(".js-slide.active");
    var i = $(slideshow).find(".js-slide").index(el);
    var length = $(slideshow).find(".js-slide").length;
    var prevEl = i < 1 ? length - 1 : i - 1;
    Slideshow.change(i, prevEl, slideshow);
  },

  change: (currentIndex, futureIndex, slideshow) => {
    var column = $(slideshow).parents(".js-column");
    var slideshowIndex = $(column).find(".js-slideshow").index(slideshow);
    $(".js-column").each(function (i, column) {
      var s = $(column).find(".js-slideshow").eq(slideshowIndex);
      var element = $(s).find(".js-slide").eq(futureIndex);
      var el =
        $(element) != undefined ? $(element) : $(s).find(".js-slide").first();
      $(s).find(".js-slide").eq(currentIndex).removeClass("active");
      $(el).addClass("active");
    });
  },
};

export default Slideshow;
