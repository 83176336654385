const Readmore = {
  init: () => {
    $(".js-readMore")
      .off("click")
      .on("click", function () {
        var column = $(this).parents(".js-column");
        var readIndex = $(column).find(".js-readMore").index($(this));
        $(".js-column").each(function (i, column) {
          var s = $(column).find(".js-readMore").eq(readIndex);
          $(s).toggleClass("active").next().slideToggle(200);
        });
      });
  },
};

export default Readmore;
