import "./_lib/modernizr";
import "../scss/application.scss";

import "nodelist-foreach-polyfill";
import "mdn-polyfills/Array.prototype.forEach";
import "mdn-polyfills/Array.prototype.find";
import "mdn-polyfills/Array.prototype.includes";
import "mdn-polyfills/String.prototype.includes";
import lazySizes from "lazysizes";
import jquery from "jquery";

window.$ = window.jQuery = jquery;

import Website from "./_modules/website";
import Cursor from "./_modules/cursor";
import Home from "./_modules/home";
import Readmore from "./_modules/readmore";
import Slideshow from "./_modules/slideshow";
import Ajax from "./_modules/ajax";

$(function () {
  Website.init();
  Cursor.init();
  Home.init();
  Readmore.init();
  Slideshow.init();
  Ajax.init();
});
