const Home = {
  init: () => {
    $("html, body").on("click", function (e) {
      if ($(".js-topContainer").hasClass("active")) {
        if (
          !$(e.target).hasClass("js-topContainer") &&
          !$(e.target).parents().hasClass("js-topContainer") &&
          !$(e.target).hasClass("js-topToggle")
        ) {
          $(".js-topContainer, .js-topToggle").removeClass("active");
          $(".js-header").removeClass("header--essay");
        }
      }
    });

    $(".js-toggleInfo").on("click", function () {
      $(".js-infoContainer, .js-toggleInfo").toggleClass("active");
      $(".js-essaysContainer, .js-toggleEssays").removeClass("active");
      $(".js-header").removeClass("header--essay");
    });

    $(".js-toggleEssays").on("click", function () {
      $(".js-essaysContainer, .js-toggleEssays").toggleClass("active");
      $(".js-header").toggleClass("header--essay");
      $(".js-infoContainer, .js-toggleInfo").removeClass("active");
    });
  },
};

export default Home;
